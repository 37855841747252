import React from "react";
import { Link } from "react-router-dom/cjs/react-router-dom";

class Hirereactjsdev extends React.Component {
  render() {
    return (
      <>
        <section id="breadcrumbs" className="breadcrumbs">
          <div className="container">
            <div className="d-flex justify-content-between align-items-center">
              <h2>Hire React.Js Developers</h2>
            </div>
          </div>
        </section>

        <div className="site-section">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 mb-4 mb-lg-0">
                <figure className="img-play-vid">
                  <img
                    src="assets/img/HIRE_ReactJs_Developer.png"
                    alt="Image"
                    className="img-fluid"
                  />
                </figure>
              </div>
              <div className="col-lg-6 ml-auto">
                <h2 className="mb-4 section-heading">Why AGM eTech</h2>
                <p>
                  AGM understands that clients prefer Node.JS, an open-source
                  JavaScript-based runtime environment for building a
                  high-performance and data-intensive application. This
                  environment also delivers maximum results and scaling options.
                  We have gathered a pool of experts and experienced Node.Js
                  developers with us. They have a refined experience and
                  gathered a high level of unmatchable expertise. CoodeIT enjoys
                  a good reputation and goodwill in the IT industry for
                  providing development and consulting services. We assure our
                  clients with the best Node.Js developers for hire. Our Node.Js
                  developers are expert enough to comply with diverse business
                  models from different industries. They are committed,
                  dedicated, and passionate to work hard and provide clients
                  with results that even exceed their expectations. For us,
                  client satisfaction is the end goal and our developers believe
                  the same. With AGM's Node.Js developers for hire, clients
                  receive end-to-end reports and communication. The developers
                  believe in staying and matching the rising standards of the
                  industry and technological change. Thus, our developers will
                  provide you with technical solutions and the design and look
                  and feel development.
                </p>
              </div>
            </div>
          </div>
        </div>

        <section id="services" className="services">
          <div className="container">
            <div className="section-title" data-aos="fade-up">
              <h2>React.Js Developers Services</h2>
            </div>
            <div className="row">
              <div className="col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0">
                <div
                  className="icon-box"
                  data-aos="fade-up"
                  data-aos-delay="100"
                >
                  <div className="icon">
                    {" "}
                    <img
                      src="assets\img\Web-Development-01.png"
                      alt="Image"
                      className="img-fluid"
                    />
                  </div>
                  <h4 className="title">
                    <Link to="">Web Development</Link>
                  </h4>
                  <p className="description">
                    Expert React.Js developers to build a scalable & robust web
                    app for you. Share the idea and relax.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0">
                <div
                  className="icon-box"
                  data-aos="fade-up"
                  data-aos-delay="200"
                >
                  <div className="icon">
                    <img
                      src="assets\img\CMS-Ecommerce-Development-01.png"
                      alt="Image"
                      className="img-fluid"
                    />
                  </div>
                  <h4 className="title">
                    <Link to="">CMS & Ecommerce Development</Link>
                  </h4>
                  <p className="description">
                    Today, enterprises are demanding React.Js performance for
                    their e-commerce venture needs. We have expert React.Js
                    developers to uplift your e-commerce & CMS development.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0">
                <div
                  className="icon-box"
                  data-aos="fade-up"
                  data-aos-delay="300"
                >
                  <div className="icon">
                    <img
                      src="assets\img\Hosting-Setting-Up-Development-Environment-01.png"
                      alt="Image"
                      className="img-fluid"
                    />
                  </div>
                  <h4 className="title">
                    <Link to="">Hosting & Setting Up Development Environment</Link>
                  </h4>
                  <p className="description">
                    CoodeIT has a pool of React.Js expert who can provide
                    cost-effective services for React.Js development environment
                    with various libraries & frameworks.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0">
                <div
                  className="icon-box"
                  data-aos="fade-up"
                  data-aos-delay="400"
                >
                  <div className="icon">
                    <img
                      src="assets\img\Fontend-Development-01.png"
                      alt="Image"
                      className="img-fluid"
                    />
                  </div>
                  <h4 className="title">
                    <Link to="">Front-End Development</Link>
                  </h4>
                  <p className="description">
                    Today enterprises also choose React.Js as it provides
                    flexibility and performance-oriented solutions to view MVC's
                    component.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0">
                <div
                  className="icon-box"
                  data-aos="fade-up"
                  data-aos-delay="400"
                >
                  <div className="icon">
                    <img
                      src="assets\img\Component-Library-Development-01.png"
                      alt="Image"
                      className="img-fluid"
                    />
                  </div>
                  <h4 className="title">
                    <Link to="">Component Library Development</Link>
                  </h4>
                  <p className="description">
                    CoodeIT promises the best React.Js developers to create and
                    use your own reusable React.Js component library to fulfill
                    your desired requirements.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0">
                <div
                  className="icon-box"
                  data-aos="fade-up"
                  data-aos-delay="400"
                >
                  <div className="icon">
                    <img
                      src="assets\img\maintenance-&-support-01.png"
                      alt="Image"
                      className="img-fluid"
                    />
                  </div>
                  <h4 className="title">
                    <Link to="">Testing and Support & Maintenance</Link>
                  </h4>
                  <p className="description">
                    Testing is the final stage, while every enterprise requires
                    support & maintenance for post-delivery services.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="services" className="services">
          <div className="container">
            <div className="section-title" data-aos="fade-up">
              <h2>Favourable Hiring Models</h2>
              <h6>
                There can never be one straight model for every business.
                CoodeIT understands that different businesses require different
                hiring models, so to meet the distinct project needs for any
                size of the business, these are the models we provide.
              </h6>
            </div>
            <div className="row">
              <div className="col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0">
                <div
                  className="icon-box"
                  data-aos="fade-up"
                  data-aos-delay="100"
                >
                  <div className="icon">
                    {" "}
                    <img
                      src="assets\img\Web-Development-01.png"
                      alt="Image"
                      className="img-fluid"
                    />
                  </div>
                  <h4 className="title">
                    <Link to="">Full-Time</Link>
                  </h4>
                  <p className="description">
                    The developer(s) will be dedicated to you for 8 hours a day
                    for five days a week. The billing is on a monthly basis,
                    with at least one month contract.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0">
                <div
                  className="icon-box"
                  data-aos="fade-up"
                  data-aos-delay="200"
                >
                  <div className="icon">
                    <img
                      src="assets\img\CMS-Ecommerce-Development-01.png"
                      alt="Image"
                      className="img-fluid"
                    />
                  </div>
                  <h4 className="title">
                    <Link to="">Part-Time</Link>
                  </h4>
                  <p className="description">
                    The developer(s) will be dedicated to you for 4 hours a day
                    for five days a week. The billing is on a monthly basis,
                    with a one month contract.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0">
                <div
                  className="icon-box"
                  data-aos="fade-up"
                  data-aos-delay="300"
                >
                  <div className="icon">
                    <img
                      src="assets\img\Hosting-Setting-Up-Development-Environment-01.png"
                      alt="Image"
                      className="img-fluid"
                    />
                  </div>
                  <h4 className="title">
                    <Link to="">Hourly Basis</Link>
                  </h4>
                  <p className="description">
                    Developer will be dedicated to you for 8 hours a day for
                    five days a week, but working hours are on your disposal.
                    Billing will be monthly, total hours in the month x the
                    pre-decided hourly rate. Minimum contract of 25 hours.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0">
                <div
                  className="icon-box"
                  data-aos="fade-up"
                  data-aos-delay="400"
                >
                  <div className="icon">
                    <img
                      src="assets\img\Fontend-Development-01.png"
                      alt="Image"
                      className="img-fluid"
                    />
                  </div>
                  <h4 className="title">
                    <Link to="">Communication</Link>
                  </h4>
                  <p className="description">
                    The formal modes of communication with the developer will be
                    through email and skype.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0">
                <div
                  className="icon-box"
                  data-aos="fade-up"
                  data-aos-delay="400"
                >
                  <div className="icon">
                    <img
                      src="assets\img\Component-Library-Development-01.png"
                      alt="Image"
                      className="img-fluid"
                    />
                  </div>
                  <h4 className="title">
                    <Link to="">Work</Link>
                  </h4>
                  <p className="description">
                    Our developers believe in quality work, whether onsite or
                    offsite. Also, they are equally capable of product
                    development and can work in unique and innovative projects.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0">
                <div
                  className="icon-box"
                  data-aos="fade-up"
                  data-aos-delay="400"
                >
                  <div className="icon">
                    <img
                      src="assets\img\maintenance-&-support-01.png"
                      alt="Image"
                      className="img-fluid"
                    />
                  </div>
                  <h4 className="title">
                    <Link to="">Focus</Link>
                  </h4>
                  <p className="description">
                    You can hire developers for a specified project or as an
                    addition to your team for a specified time.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default Hirereactjsdev;

import React from "react";
import { Link } from "react-router-dom/cjs/react-router-dom";

class Hybridappdevelopment extends React.Component {
  render() {
    return (
      <>
        <section id="breadcrumbs" className="breadcrumbs">
          <div className="container">
            <div className="d-flex justify-content-between align-items-center">
              <h2>Cross-Platform Apps</h2>
            </div>
          </div>
        </section>

        <section id="portfolio-details" className="portfolio-details">
          <div className="container">
            <div className="row gy-4"></div>
          </div>
        </section>

        <div className="site-section">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 mb-4 mb-lg-0">
                <figure className="img-play-vid">
                  <img
                    src="assets/img/hybrid.png"
                    alt="Image"
                    className="img-fluid"
                  />
                </figure>
              </div>
              <div className="col-lg-6 ml-auto">
                <h2 className="mb-4 section-heading">Why AGM eTech</h2>
                <p>
                  IAt AGM, we believe in offering compelling mobile app
                  solutions to our clients. We have an aggressive approach
                  towards adapting to the latest and unique developments in
                  cross-platform app development. Our cross-platform app
                  developers follow a unique methodology for developing mobile
                  apps as per the client's business requirements. Our team
                  considers even the minutest details while developing the
                  sample and actual app. We aim to provide highly functional and
                  well-defined cross-platform apps that are compatible with all
                  devices. These apps shall not comprise effectiveness or
                  efficiency. We develop cross-platform apps for React Native,
                  TypeScrip, and Flutter technologies. AGM Solutions enjoys a
                  good reputation and confidence from its client for various
                  ideas and innovations it suggests. Our team includes expert
                  and experienced app developers who have always proven to
                  deliver according to the client's expectations and under the
                  deadline.
                </p>
              </div>
            </div>
          </div>
        </div>

        <section id="services" className="services">
          <div className="container">
            <div className="section-title" data-aos="fade-up">
              <h2>Our Cross-Platform Developers Provide</h2>
            </div>
            <div className="row">
              <div className="col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0">
                <div
                  className="icon-box"
                  data-aos="fade-up"
                  data-aos-delay="100"
                >
                  <div className="icon">
                    <img
                      src="assets\img\Full-TIme-01.png"
                      alt="Image"
                      className="img-fluid"
                    />
                  </div>
                  <h4 className="title">
                    <Link to="">High Expertise</Link>
                  </h4>
                  <p className="description">
                    TWe always focus and emphasize developing and expanding the
                    skill set of our team and organization as a whole—iOS
                    demands for advancement and dynamicity in every developer.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0">
                <div
                  className="icon-box"
                  data-aos="fade-up"
                  data-aos-delay="200"
                >
                  <div className="icon">
                    <img
                      src="assets\img\Part-TIme-01.png"
                      alt="Image"
                      className="img-fluid"
                    />
                  </div>
                  <h4 className="title">
                    <Link to="">Effective</Link>
                  </h4>
                  <p className="description">
                    We understand that time is money, and in the IT industry,
                    the faster your work, and the quicker you launch, the more
                    successful you can get. Our team is committed to providing
                    quality results within the decided deadlines.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0"></div>
              <div className="col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0">
                <div
                  className="icon-box"
                  data-aos="fade-up"
                  data-aos-delay="400"
                >
                  <div className="icon">
                    <img
                      src="assets\img\FullControl-01.png"
                      alt="Image"
                      className="img-fluid"
                    />
                  </div>
                  <h4 className="title">
                    <Link to="">Diverse Options</Link>
                  </h4>
                  <p className="description">
                    Our team is well versed in Object C and Swift iOS
                    technology. We can also develop apps for iPhone, iPad, Apple
                    TV, Apple Watch, and more, with the best design and
                    necessary customization.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0">
                <div
                  className="icon-box"
                  data-aos="fade-up"
                  data-aos-delay="400"
                >
                  <div className="icon">
                    <img
                      src="assets\img\FullTransparency-01.png"
                      alt="Image"
                      className="img-fluid"
                    />
                  </div>
                  <h4 className="title">
                    <Link to="">Diverse Experience</Link>
                  </h4>
                  <p className="description">
                    Our developers have worked for various businesses in
                    different industries. Along with app development services,
                    they are also experienced in finding and revamping a
                    dysfunctioning app.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0"></div>
            </div>
          </div>
        </section>

        <section id="services" className="services">
          <div className="container">
            <div className="section-title" data-aos="fade-up">
              <h2>Services In Cross-Platform App Development</h2>
            </div>
            <div className="row">
              <div className="col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0">
                <div className="icon-box">
                  <div className="icon">
                    <img
                      src="assets\img\Full-TIme-01.png"
                      alt="Image"
                      className="img-fluid"
                    />
                  </div>
                  <h4 className="title">
                    <Link to="">Understanding Your Idea</Link>
                  </h4>
                  <p className="description">
                    We start by designing the necessary prototypes, wireframes,
                    and mock-ups. Here we will provide you some options to
                    choose from. As you select one, we will move to the coding
                    cycle.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0">
                <div
                  className="icon-box"
                  data-aos="fade-up"
                  data-aos-delay="200"
                >
                  <div className="icon">
                    <img
                      src="assets\img\Part-TIme-01.png"
                      alt="Image"
                      className="img-fluid"
                    />
                  </div>
                  <h4 className="title">
                    <Link to="">Responsive Nature</Link>
                  </h4>
                  <p className="description">
                    The cross-platform apps have a wider scope of being more
                    responsive which also increases positive user experience.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0">
                <div
                  className="icon-box"
                  data-aos="fade-up"
                  data-aos-delay="300"
                >
                  <div className="icon">
                    <img
                      src="assets\img\Affordable-01.png"
                      alt="Image"
                      className="img-fluid"
                    />
                  </div>
                  <h4 className="title">
                    <Link to="">Hybrid Apps</Link>
                  </h4>
                  <p className="description">
                    We also provide hybrid apps for your innovative ideas that
                    require the integration of native and web application
                    elements.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0">
                <div
                  className="icon-box"
                  data-aos="fade-up"
                  data-aos-delay="400"
                >
                  <div className="icon">
                    <img
                      src="assets\img\FullControl-01.png"
                      alt="Image"
                      className="img-fluid"
                    />
                  </div>
                  <h4 className="title">
                    <Link to="">Migration Services</Link>
                  </h4>
                  <p className="description">
                    Many web apps are being migrated to a fully functional
                    cross-platform app, and our developers are experts in it.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0">
                <div
                  className="icon-box"
                  data-aos="fade-up"
                  data-aos-delay="400"
                >
                  <div className="icon">
                    <img
                      src="assets\img\FullTransparency-01.png"
                      alt="Image"
                      className="img-fluid"
                    />
                  </div>
                  <h4 className="title">
                    <Link to="">Cloud Development</Link>
                  </h4>
                  <p className="description">
                    Today users prefer the apps that can connect with the cloud.
                    This provides them extra space and easier access to data.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0">
                <div
                  className="icon-box"
                  data-aos="fade-up"
                  data-aos-delay="400"
                >
                  <div className="icon">
                    <img
                      src="assets\img\QuickProcess-01.png"
                      alt="Image"
                      className="img-fluid"
                    />
                  </div>
                  <h4 className="title">
                    <Link to="">Post-App Delivery</Link>
                  </h4>
                  <p className="description">
                    Post-delivery, every app demands regular maintenance and
                    up-gradation; we also provide contracts for the same.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default Hybridappdevelopment;

import React from 'react';
import Carousel from 'nuka-carousel';

class Portfolio extends React.Component {
    render() {
        return (
            <>
                <section id="Portfolio" className="Portfolio">
                    <div className="section-title" data-aos="fade-up">
                        <h2>Portfolio</h2>
                    </div>
                    <div className="container">
                        <Carousel>                    
                            <div className="row">
                                <div className="col-12">
                                    <div className="work-carousel">
                                        <div className="owl-stage-outer">
                                            <div className="owl-stage">
                                                <div className="owl-item cloned">
                                                    <div className="item">
                                                        <div className="row">
                                                            <div className="col-12 col-md-6">
                                                                <div className="work-content pr-md-5">
                                                                    <h4>
                                                                        Micronics</h4>
                                                                    <div className="pdis">
                                                                        <h6>
                                                                            Industry</h6>
                                                                        <p>
                                                                            Data Recorvery Company </p>
                                                                    </div>
                                                                    <div className="pdis">
                                                                        <h6>
                                                                            Technologies</h6>
                                                                        <p>
                                                                            .net </p>
                                                                    </div>
                                                                    <div className="pdis">
                                                                        <h6>
                                                                            Details</h6>
                                                                        <p>
                                                                           Micronics is India's best company for Data recovery from HDD, Mobile, Server </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-6">
                                                                <figure><img src="assets/img/Desktop-1.png" alt="Positive Results" /></figure>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                           
                            <div className="row">
                            <div className="col-12 col-md-6">
                                    <figure><img src="assets/img/Desktop-3.png" alt="Free4Me iPhone App" /></figure>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="work-content pr-md-5">
                                        <h4>
                                            SR Design & Construction</h4>
                                        <div className="pdis">
                                            <h6>
                                                Industry</h6>
                                            <p>
                                           Planning, interiors Design and 3D modeling</p>
                                        </div>
                                     
                                        <div className="pdis">
                                            <h6>
                                                Details</h6>
                                            <p>
                                            We are strong, sharp and experienced team of design, construction, interiors & 3D modeling, ably supported by our technical, administrative & back-end staff.</p>
                                        </div>
                                    </div>
                                </div>
                               
                            </div>
                            <div className="row">
                            
                                <div className="col-12 col-md-6">
                                    <div className="work-content pr-md-5">
                                        <h4>
                                            Khaugali - Restaurant App</h4>
                                        <div className="pdis">
                                            <h6>
                                                Industry</h6>
                                            <p>
                                                Restaurant</p>
                                        </div>
                                       
                                        <div className="pdis">
                                            <h6>
                                                Details</h6>
                                            <p>
                                               Captain take order from mobile.Admin can manage by web admin panel and  desktop application. </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <figure><img src="assets/img/Khaugali.png" alt="Khaugali Restaurant Application" /></figure>
                                </div>
                            </div>
                            <div className="row">
                            <div className="col-12 col-md-6">
                                    <figure><img src="assets/img/Desktop-4.png" alt="Free4Me iPhone App" /></figure>
                                </div>
                            <div className="col-12 col-md-6">
                                    <div className="work-content pr-md-5">
                                        <h4>
                                            AD Publication</h4>
                                        <div className="pdis">
                                            <h6>
                                                Industry</h6>
                                            <p>
                                                Publications</p>
                                        </div>
                                       
                                        <div className="pdis">
                                            <h6>
                                                Details</h6>
                                            <p>
                                                Author Submit his/her paper, Reviewer and editor check papers and give certificate.</p>
                                        </div>
                                    </div>
                                </div>
                             
                            </div>

                            <div className="row">
                            <div className="col-12 col-md-6">
                                    <div className="work-content pr-md-5">
                                        <h4>
                                            Farm to Factory</h4>
                                        <div className="pdis">
                                            <h6>
                                                Industry</h6>
                                            <p>
                                                 Tradding platform for Farmers,Pastrolist,Factory,Traders </p>
                                        </div>
                                       
                                        <div className="pdis">
                                            <h6>
                                                Details</h6>
                                            <p>
                                                Farmers,Pastrolist,Factory and Traders can post for buy and sale products.Mobile application in three languages Englsih, Hindi and Gujarati</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <figure><img src="assets/img/iPhoneSet.png" alt="Free4Me iPhone App" /></figure>
                                </div>
                            </div>
                        </Carousel>
                    </div>
                </section>
            </>
        );
    }
}
export default Portfolio;

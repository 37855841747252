import React from "react";
import { Link } from "react-router-dom/cjs/react-router-dom";

class Customersoftwaredev extends React.Component {
  render() {
    return (
      <>
        <section id="breadcrumbs" className="breadcrumbs">
          <div className="container">
            <div className="d-flex justify-content-between align-items-center">
              <h2>Custom Software Development</h2>
            </div>
          </div>
        </section>

        <div className="site-section">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 mb-4 mb-lg-0">
                <figure className="img-play-vid">
                  <img
                    src="assets/img/Custom_Software_development.png"
                    alt="Image"
                    className="img-fluid"
                  />
                </figure>
              </div>
              <div className="col-lg-6 ml-auto">
                <h2 className="mb-4 section-heading">Why AGM eTech</h2>
                <p>
                  Today, custom software development services have a huge
                  market, and it's only increasing. With the ongoing
                  digitalization wave, companies understand the need to have
                  customized software so their business processes can be
                  continued in the fashion they prefer and their methodology.
                  AGM helps companies with technological solutions drawn from
                  emerging technologies and innovation. Custom software
                  development services are very diverse, and we look forward to
                  satisfying each client for their unique needs and
                  expectations. Our developers provide software solutions that
                  enable increased productivity and the necessary degree of
                  automation. They strive to deliver quality software
                  application delivery while always being considerate of time
                  and budgetary limits. With sustaining customization, it is
                  also essential to look after maintenance and a good support
                  plan. Our team takes care of those requirements as well. With
                  initial meetings, we will understand your idea and draw clear
                  project goals and development plans. We promise smooth
                  planning, strategizing, coding, testing, and implementing
                  services. The technologies are evolving like anything, and we
                  make sure that clients receive the best product that stands in
                  the market for the long-term. Our team self-evaluates the work
                  in between and also shares the progress reports. We always
                  stay in contact with the client and provide honest and
                  transparent communication.
                </p>
              </div>
            </div>
          </div>
        </div>

        <section id="services" className="services">
          <div className="container">
            <div className="section-title" data-aos="fade-up">
              <h2>Our Software Developers Promise</h2>
            </div>
            <div className="row">
              <div className="col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0">
                <div
                  className="icon-box"
                  data-aos="fade-up"
                  data-aos-delay="100"
                >
                  <div className="icon">
                    <img
                      src="assets\img\Full-TIme-01.png"
                      alt="Image"
                      className="img-fluid"
                    />
                  </div>
                  <h4 className="title">
                    <Link to="">Well-thought Strategies</Link>
                  </h4>
                  <p className="description">
                    Understand the client's business, requirements,
                    expectations, vision, and demand is the foundation of every
                    project—such foundation results in plans and working
                    strategies that result in progressive and cooperative
                    business relationships.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0">
                <div
                  className="icon-box"
                  data-aos="fade-up"
                  data-aos-delay="200"
                >
                  <div className="icon">
                    <img
                      src="assets\img\Part-TIme-01.png"
                      alt="Image"
                      className="img-fluid"
                    />
                  </div>
                  <h4 className="title">
                    <Link to="">Efficient</Link>
                  </h4>
                  <p className="description">
                    Time plays an essential factor in the IT industry. Working
                    as per the deadlines, schedules, and meetings are crucial
                    and highly cost-effective. We promise to deliver clients
                    high-quality products at the decided time and cost.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0"></div>
              <div className="col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0">
                <div
                  className="icon-box"
                  data-aos="fade-up"
                  data-aos-delay="400"
                >
                  <div className="icon">
                    <img
                      src="assets\img\FullControl-01.png"
                      alt="Image"
                      className="img-fluid"
                    />
                  </div>
                  <h4 className="title">
                    <Link to="">Client Centricity</Link>
                  </h4>
                  <p className="description">
                    Our motive is to provide software solutions that optimize or
                    elegantly transform your business processes by improving
                    their transparency, employees' efficiency, collaboration
                    within the department, and the smoothness in operations.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0">
                <div
                  className="icon-box"
                  data-aos="fade-up"
                  data-aos-delay="400"
                >
                  <div className="icon">
                    <img
                      src="assets\img\FullTransparency-01.png"
                      alt="Image"
                      className="img-fluid"
                    />
                  </div>
                  <h4 className="title">
                    <Link to="">Diverse Experience</Link>
                  </h4>
                  <p className="description">
                    Have a deep understanding of a wide range of technologies
                    and their applications in diverse businesses is important in
                    the IT industry. Well designed software adds value to the
                    business.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0"></div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default Customersoftwaredev;

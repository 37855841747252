import React from "react";
import { Link } from "react-router-dom/cjs/react-router-dom";

class Hireecommercedev extends React.Component {
  render() {
    return (
      <>
        <section id="breadcrumbs" className="breadcrumbs">
          <div className="container">
            <div className="d-flex justify-content-between align-items-center">
              <h2>Hire E-commerce Developers</h2>
            </div>
          </div>
        </section>
        <div className="site-section">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 mb-4 mb-lg-0">
                <figure className="img-play-vid">
                  <img
                    src="assets/img/HIRE_eCommerce_Developer.png"
                    alt="Image"
                    className="img-fluid"
                  />
                </figure>
              </div>
              <div className="col-lg-6 ml-auto">
                <h2 className="mb-4 section-heading">Why AGM eTech</h2>
                <p>
                  Today, Java is the most popular and most used programming
                  language. In fact, it is the foundation of many new languages
                  and technologies, so clients require expert and experienced
                  Java developers for hire who can provide them the advance and
                  quality services in this language. Whether you have the idea
                  of creating an innovative mobile app, customized software,
                  scalable e-commerce solutions, or even complex websites, Java
                  is always one of the intelligent options. Java is often used
                  in front-end and back-end programming and as a CMS part of a
                  Java website. To realize your idea effectively, you need the
                  best Java developers to leverage Java's complete abilities for
                  your project. At CoodeIT, we provide you with a pool of
                  resumes that are already tested and checked for expertise and
                  experience. Along with that, we also assist you in procuring
                  the best professional as per your project type. Our Java
                  developers are committed to offering quality service for
                  creating feature-rich projects with a world-class support
                  system. The developers have a proven record of the utmost
                  quality, stability, scalability, and timely delivery. They are
                  dynamic, well-researched, and aware of the changing
                  technologies and innovations.
                </p>
              </div>
            </div>
          </div>
        </div>
        <section id="services" className="services">
          <div className="container">
            <div className="section-title" data-aos="fade-up">
              <h2>E-commerce Developers Services</h2>
            </div>
            <div className="row">
              <div className="col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0">
                <div
                  className="icon-box"
                  data-aos="fade-up"
                  data-aos-delay="100"
                >
                  <div className="icon">
                    <img
                      src="assets\img\Custom-Website-Design-&-Development.png"
                      alt="Image"
                      className="img-fluid"
                    />
                  </div>
                  <h4 className="title">
                    <Link to="">Custom-Website-Design-&-Development</Link>
                  </h4>
                  <p className="description">
                    Creative designing and effective development plays an
                    essential role in e-commerce development. You need to be
                    prepared for high traffic, while also providing a smooth and
                    personalized experience to every visitor. Our developers
                    will realize your idea like a whole new world to the user.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0">
                <div
                  className="icon-box"
                  data-aos="fade-up"
                  data-aos-delay="200"
                >
                  <div className="icon">
                    <img
                      src="assets\img\Application-Development-01.png"
                      alt="Image"
                      className="img-fluid"
                    />
                  </div>
                  <h4 className="title">
                    <Link to="">Application Development</Link>
                  </h4>
                  <p className="description">
                    Our developers are skilled enough to provide a satisfying
                    web browser, mobile apps, and responsive e-commerce
                    applications. Especially in B2C, mobile apps are gaining
                    high popularity,, and our e-commerce developers strive to
                    provide the best customer experience.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0">
                <div
                  className="icon-box"
                  data-aos="fade-up"
                  data-aos-delay="300"
                >
                  <div className="icon">
                    <img
                      src="assets\img\Shopping-Cart-Solutions-01.png"
                      alt="Image"
                      className="img-fluid"
                    />
                  </div>
                  <h4 className="title">
                    <Link to="">Shopping Cart Solutions</Link>
                  </h4>
                  <p className="description">
                    Having innovative and smooth shopping cart solutions is also
                    an important feature. During e-commerce development, the
                    shopping cart needs to be considered as an individually
                    different aspect. Clients require superior fraud protection
                    systems and a reliable cart feature fro users.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0">
                <div
                  className="icon-box"
                  data-aos="fade-up"
                  data-aos-delay="400"
                >
                  <div className="icon">
                    <img
                      src="assets\img\Secure-And-Scalable-Web-Hosting-01.png"
                      alt="Image"
                      className="img-fluid"
                    />
                  </div>
                  <h4 className="title">
                    <Link to="">Secure And Scalable Web Hosting</Link>
                  </h4>
                  <p className="description">
                    An e-commerce portal needs to be prepared for thousands of
                    clicks and actions on the portal. Our e-commerce web
                    developers provide an effective hosting service that can
                    scale up smoothly, provide 100% security, and handle traffic
                    without affecting performance.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0">
                <div
                  className="icon-box"
                  data-aos="fade-up"
                  data-aos-delay="400"
                >
                  <div className="icon">
                    <img
                      src="assets\img\Secured-Payment-Gateway-Integration-01.png"
                      alt="Image"
                      className="img-fluid"
                    />
                  </div>
                  <h4 className="title">
                    <Link to="">Secured Payment Gateway Integration</Link>
                  </h4>
                  <p className="description">
                    E-commerce developers also need to integrate a secure and
                    reliable payment gateway. The client and their user's
                    transaction safety is an important aspect. CoodeIT strives
                    to provide reliable integration with the client's choice of
                    the payment gateway.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0">
                <div
                  className="icon-box"
                  data-aos="fade-up"
                  data-aos-delay="400"
                >
                  <div className="icon">
                    <img
                      src="assets\img\Support-&-Maintenance-Services-01.png"
                      alt="Image"
                      className="img-fluid"
                    />
                  </div>
                  <h4 className="title">
                    <Link to="">Maintenance & Support</Link>
                  </h4>
                  <p className="description">
                    Websites, especially e-commerce, require consistent
                    maintenance. This is to sustain their maximum performance.
                    Our experienced e-commerce developers offer quality
                    maintenance and support services. We believe in increasing
                    user satisfaction and enriching their experience.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="services" className="services">
          <div className="container">
            <div className="section-title" data-aos="fade-up">
              <h2>Favourable Hiring Models</h2>
            </div>
            <div className="row">
              <div className="col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0">
                <div
                  className="icon-box"
                  data-aos="fade-up"
                  data-aos-delay="100"
                >
                  <div className="icon">
                    <img
                      src="assets\img\Full-TIme-01.png"
                      alt="Image"
                      className="img-fluid"
                    />
                  </div>
                  <h4 className="title">
                    <Link to="">Full-Time</Link>
                  </h4>
                  <p className="description">
                    We provide our clients with specific enterprise solutions
                    for their unique and varied business needs. For providing
                    the best services, our team makes sure to do the necessary
                    research regarding innovation and current & future trends.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0">
                <div
                  className="icon-box"
                  data-aos="fade-up"
                  data-aos-delay="200"
                >
                  <div className="icon">
                    <img
                      src="assets\img\Part-TIme-01.png"
                      alt="Image"
                      className="img-fluid"
                    />
                  </div>
                  <h4 className="title">
                    <Link to="">Part-Time</Link>
                  </h4>
                  <p className="description">
                    The The developer(s) will be dedicated to you for 4 hours a
                    day for five days a week. The billing is on a monthly basis,
                    with a one month contract.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0">
                <div
                  className="icon-box"
                  data-aos="fade-up"
                  data-aos-delay="300"
                >
                  <div className="icon">
                    <img
                      src="assets\img\Affordable-01.png"
                      alt="Image"
                      className="img-fluid"
                    />
                  </div>
                  <h4 className="title">
                    <Link to="">Hourly Basis</Link>
                  </h4>
                  <p className="description">
                    Developer will be dedicated to you for 8 hours a day for
                    five days a week, but working hours are on your disposal.
                    Billing will be monthly, total hours in the month x the
                    pre-decided hourly rate. Minimum contract of 25 hours.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0">
                <div
                  className="icon-box"
                  data-aos="fade-up"
                  data-aos-delay="400"
                >
                  <div className="icon">
                    <img
                      src="assets\img\FullControl-01.png"
                      alt="Image"
                      className="img-fluid"
                    />
                  </div>
                  <h4 className="title">
                    <Link to="">Communication</Link>
                  </h4>
                  <p className="description">
                    The formal modes of communication with the developer will be
                    through email and skype.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0">
                <div
                  className="icon-box"
                  data-aos="fade-up"
                  data-aos-delay="400"
                >
                  <div className="icon">
                    <img
                      src="assets\img\FullTransparency-01.png"
                      alt="Image"
                      className="img-fluid"
                    />
                  </div>
                  <h4 className="title">
                    <Link to="">Work</Link>
                  </h4>
                  <p className="description">
                    Our developers believe in quality work, whether onsite or
                    offsite. Also, they are equally capable of product
                    development and can work in unique and innovative projects.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0">
                <div
                  className="icon-box"
                  data-aos="fade-up"
                  data-aos-delay="400"
                >
                  <div className="icon">
                    <img
                      src="assets\img\QuickProcess-01.png"
                      alt="Image"
                      className="img-fluid"
                    />
                  </div>
                  <h4 className="title">
                    <Link to="">Focus</Link>
                  </h4>
                  <p className="description">
                    You can hire developers for a specified project or as an
                    addition to your team for a specified time.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="services" className="services">
          <div className="container">
            <div className="section-title" data-aos="fade-up">
              <h2>Why Choose Us</h2>
            </div>
            <div className="row">
              <div className="col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0">
                <div
                  className="icon-box"
                  data-aos="fade-up"
                  data-aos-delay="100"
                >
                  <div className="icon">
                    <img
                      src="assets\img\ChooseExpert-01.png"
                      alt="Image"
                      className="img-fluid"
                    />
                  </div>
                  <h4 className="title">
                    <Link to="">Full-Time</Link>
                  </h4>
                  <p className="description">
                    We provide our clients with specific enterprise solutions
                    for their unique and varied business needs. For providing
                    the best services, our team makes sure to do the necessary
                    research regarding innovation and current & future trends.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0">
                <div
                  className="icon-box"
                  data-aos="fade-up"
                  data-aos-delay="200"
                >
                  <div className="icon">
                    <img
                      src="assets\img\ClearCommunication-01.png"
                      alt="Image"
                      className="img-fluid"
                    />
                  </div>
                  <h4 className="title">
                    <Link to="">Part-Time</Link>
                  </h4>
                  <p className="description">
                    The The developer(s) will be dedicated to you for 4 hours a
                    day for five days a week. The billing is on a monthly basis,
                    with a one month contract.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0">
                <div
                  className="icon-box"
                  data-aos="fade-up"
                  data-aos-delay="300"
                >
                  <div className="icon">
                    <img
                      src="assets\img\Affordable-01.png"
                      alt="Image"
                      className="img-fluid"
                    />
                  </div>
                  <h4 className="title">
                    <Link to="">Hourly Basis</Link>
                  </h4>
                  <p className="description">
                    Developer will be dedicated to you for 8 hours a day for
                    five days a week, but working hours are on your disposal.
                    Billing will be monthly, total hours in the month x the
                    pre-decided hourly rate. Minimum contract of 25 hours.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0">
                <div
                  className="icon-box"
                  data-aos="fade-up"
                  data-aos-delay="400"
                >
                  <div className="icon">
                    <img
                      src="assets\img\Communication-01.png"
                      alt="Image"
                      className="img-fluid"
                    />
                  </div>
                  <h4 className="title">
                    <Link to="">Communication</Link>
                  </h4>
                  <p className="description">
                    The formal modes of communication with the developer will be
                    through email and skype.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0">
                <div
                  className="icon-box"
                  data-aos="fade-up"
                  data-aos-delay="400"
                >
                  <div className="icon">
                    <img
                      src="assets\img\Work.png"
                      alt="Image"
                      className="img-fluid"
                    />
                  </div>
                  <h4 className="title">
                    <Link to="">Work</Link>
                  </h4>
                  <p className="description">
                    Our developers believe in quality work, whether onsite or
                    offsite. Also, they are equally capable of product
                    development and can work in unique and innovative projects.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0">
                <div
                  className="icon-box"
                  data-aos="fade-up"
                  data-aos-delay="400"
                >
                  <div className="icon">
                    <img
                      src="assets\img\Focus-01.png"
                      alt="Image"
                      className="img-fluid"
                    />
                  </div>
                  <h4 className="title">
                    <Link to="">Focus</Link>
                  </h4>
                  <p className="description">
                    You can hire developers for a specified project or as an
                    addition to your team for a specified time.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default Hireecommercedev;

import React from "react";
import { Link } from "react-router-dom/cjs/react-router-dom";

class Hirejavadev extends React.Component {
  render() {
    return (
      <>
        <section id="breadcrumbs" className="breadcrumbs">
          <div className="container">
            <div className="d-flex justify-content-between align-items-center">
              <h2>Hire Java Developers</h2>
            </div>
          </div>
        </section>

        <div className="site-section">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 mb-4 mb-lg-0">
                <figure className="img-play-vid">
                  <img
                    src="assets/img/HIRE_Java_Developer.png"
                    alt="Image"
                    className="img-fluid"
                  />
                </figure>
              </div>
              <div className="col-lg-6 ml-auto">
                <h2 className="mb-4 section-heading">Why AGM eTech</h2>
                <p>
                  Today, Java is the most popular and most used programming
                  language. In fact, it is the foundation of many new languages
                  and technologies, so clients require expert and experienced
                  Java developers for hire who can provide them the advance and
                  quality services in this language. Whether you have the idea
                  of creating an innovative mobile app, customized software,
                  scalable e-commerce solutions, or even complex websites, Java
                  is always one of the intelligent options. Java is often used
                  in front-end and back-end programming and as a CMS part of a
                  Java website. To realize your idea effectively, you need the
                  best Java developers to leverage Java's complete abilities for
                  your project. At AGM, we provide you with a pool of resumes
                  that are already tested and checked for expertise and
                  experience. Along with that, we also assist you in procuring
                  the best professional as per your project type. Our Java
                  developers are committed to offering quality service for
                  creating feature-rich projects with a world-class support
                  system. The developers have a proven record of the utmost
                  quality, stability, scalability, and timely delivery. They are
                  dynamic, well-researched, and aware of the changing
                  technologies and innovations.
                </p>
              </div>
            </div>
          </div>
        </div>

        <section id="services" className="services">
          <div className="container">
            <div className="section-title" data-aos="fade-up">
              <h2>Java Developers Services</h2>
            </div>
            <div className="row">
              <div className="col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0">
                <div
                  className="icon-box"
                  data-aos="fade-up"
                  data-aos-delay="100"
                >
                  <div className="icon">
                    <img
                      src="assets\img\Web-Development-01.png"
                      alt="Image"
                      className="img-fluid"
                    />
                  </div>
                  <h4 className="title">
                    <Link to="">Web Development</Link>
                  </h4>
                  <p className="description">
                    AGM provides multiple IT development services, and we have
                    an expert team of Java developers to assist you in building
                    scalable and secure solutions by adopting the latest tools
                    and technologies.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0">
                <div
                  className="icon-box"
                  data-aos="fade-up"
                  data-aos-delay="200"
                >
                  <div className="icon">
                    <img
                      src="assets\img\Custom-Development-01.png"
                      alt="Image"
                      className="img-fluid"
                    />
                  </div>
                  <h4 className="title">
                    <Link to="">Custom Development</Link>
                  </h4>
                  <p className="description">
                    Hire Java programmers to specialize in providing clients
                    with customized Java web development services to realize
                    their unique business plans and goals.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0">
                <div
                  className="icon-box"
                  data-aos="fade-up"
                  data-aos-delay="300"
                >
                  <div className="icon">
                    <img
                      src="assets\img\Product-Development-01.png"
                      alt="Image"
                      className="img-fluid"
                    />
                  </div>
                  <h4 className="title">
                    <Link to="">Product Development</Link>
                  </h4>
                  <p className="description">
                    Expert Java developers who can take care of your product
                    development from conception, development to testing &
                    technical implementation.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0">
                <div
                  className="icon-box"
                  data-aos="fade-up"
                  data-aos-delay="400"
                >
                  <div className="icon">
                    <img
                      src="assets\img\Enterprise-Development-01.png"
                      alt="Image"
                      className="img-fluid"
                    />
                  </div>
                  <h4 className="title">
                    <Link to="">Enterprise Development</Link>
                  </h4>
                  <p className="description">
                    Some businesses require customized business applications.
                    CoodeIT has expert Java developers to understand your
                    business, requirements, and diverse industry verticals to
                    provide you with the perfect solution.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0">
                <div
                  className="icon-box"
                  data-aos="fade-up"
                  data-aos-delay="400"
                >
                  <div className="icon">
                    <img
                      src="assets\img\System-Integration-01.png"
                      alt="Image"
                      className="img-fluid"
                    />
                  </div>
                  <h4 className="title">
                    <Link to="">System Integration</Link>
                  </h4>
                  <p className="description">
                    For better performance and security reasons, clients prefer
                    to integrate their Java applications to chosen or consulted
                    Java frameworks.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0">
                <div
                  className="icon-box"
                  data-aos="fade-up"
                  data-aos-delay="400"
                >
                  <div className="icon">
                    <img
                      src="assets\img\maintenance-&-support-01.png"
                      alt="Image"
                      className="img-fluid"
                    />
                  </div>
                  <h4 className="title">
                    <Link to="">Maintenance & Support</Link>
                  </h4>
                  <p className="description">
                    In the case of digital products, clients need experts for
                    consistent product maintenance & support services so that
                    application performance can be monitored and maintained for
                    a stable environment.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="services" className="services">
          <div className="container">
            <div className="section-title" data-aos="fade-up">
              <h2>Favourable Hiring Models</h2>
              <h6>
                There can never be one straight model for every business.
                CoodeIT understands that different businesses require different
                hiring models, so to meet the distinct project needs for any
                size of the business, these are the models we provide.
              </h6>
            </div>
            <div className="row">
              <div className="col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0">
                <div
                  className="icon-box"
                  data-aos="fade-up"
                  data-aos-delay="100"
                >
                  <div className="icon">
                    <img
                      src="assets\img\Full-TIme-01.png"
                      alt="Image"
                      className="img-fluid"
                    />
                  </div>
                  <h4 className="title">
                    <Link to="">Full-Time</Link>
                  </h4>
                  <p className="description">
                    The developer(s) will be dedicated to you for 8 hours a day
                    for five days a week. The billing is on a monthly basis,
                    with at least one month contract.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0">
                <div
                  className="icon-box"
                  data-aos="fade-up"
                  data-aos-delay="200"
                >
                  <div className="icon">
                    <img
                      src="assets\img\Part-TIme-01.png"
                      alt="Image"
                      className="img-fluid"
                    />
                  </div>
                  <h4 className="title">
                    <Link to="">Part-Time</Link>
                  </h4>
                  <p className="description">
                    The The developer(s) will be dedicated to you for 4 hours a
                    day for five days a week. The billing is on a monthly basis,
                    with a one month contract.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0">
                <div
                  className="icon-box"
                  data-aos="fade-up"
                  data-aos-delay="300"
                >
                  <div className="icon">
                    <img
                      src="assets\img\Affordable-01.png"
                      alt="Image"
                      className="img-fluid"
                    />
                  </div>
                  <h4 className="title">
                    <Link to="">Hourly Basis</Link>
                  </h4>
                  <p className="description">
                    Developer will be dedicated to you for 8 hours a day for
                    five days a week, but working hours are on your disposal.
                    Billing will be monthly, total hours in the month x the
                    pre-decided hourly rate. Minimum contract of 25 hours.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0">
                <div
                  className="icon-box"
                  data-aos="fade-up"
                  data-aos-delay="400"
                >
                  <div className="icon">
                    <img
                      src="assets\img\FullControl-01.png"
                      alt="Image"
                      className="img-fluid"
                    />
                  </div>
                  <h4 className="title">
                    <Link to="">Communication</Link>
                  </h4>
                  <p className="description">
                    The formal modes of communication with the developer will be
                    through email and skype.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0">
                <div
                  className="icon-box"
                  data-aos="fade-up"
                  data-aos-delay="400"
                >
                  <div className="icon">
                    <img
                      src="assets\img\FullTransparency-01.png"
                      alt="Image"
                      className="img-fluid"
                    />
                  </div>
                  <h4 className="title">
                    <Link to="">Work</Link>
                  </h4>
                  <p className="description">
                    Our developers believe in quality work, whether onsite or
                    offsite. Also, they are equally capable of product
                    development and can work in unique and innovative projects.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0">
                <div
                  className="icon-box"
                  data-aos="fade-up"
                  data-aos-delay="400"
                >
                  <div className="icon">
                    <img
                      src="assets\img\QuickProcess-01.png"
                      alt="Image"
                      className="img-fluid"
                    />
                  </div>
                  <h4 className="title">
                    <Link to="">Focus</Link>
                  </h4>
                  <p className="description">
                    You can hire developers for a specified project or as an
                    addition to your team for a specified time.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="services" className="services">
          <div className="container">
            <div className="section-title" data-aos="fade-up">
              <h2>Why Choose Us</h2>
            </div>
            <div className="row">
              <div className="col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0">
                <div
                  className="icon-box"
                  data-aos="fade-up"
                  data-aos-delay="100"
                >
                  <div className="icon">
                    <img
                      src="assets\img\Full-TIme-01.png"
                      alt="Image"
                      className="img-fluid"
                    />
                  </div>
                  <h4 className="title">
                    <Link to="">Choose Experts</Link>
                  </h4>
                  <p className="description">
                    We have a pool of expert and experienced Java developers who
                    are selected after an extensive process of screening,
                    interview, and tests. The resumes we present to our clients
                    are of the developers we have utmost faith to be best for
                    your project.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0">
                <div
                  className="icon-box"
                  data-aos="fade-up"
                  data-aos-delay="200"
                >
                  <div className="icon">
                    <img
                      src="assets\img\Part-TIme-01.png"
                      alt="Image"
                      className="img-fluid"
                    />
                  </div>
                  <h4 className="title">
                    <Link to="">Clear Communication</Link>
                  </h4>
                  <p className="description">
                    During meetings, during the project, and post-project, the
                    clients can freely contact developers through email and
                    skype. The chosen developers will also contact you through
                    phone or video conference if required.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0">
                <div
                  className="icon-box"
                  data-aos="fade-up"
                  data-aos-delay="300"
                >
                  <div className="icon">
                    <img
                      src="assets\img\Affordable-01.png"
                      alt="Image"
                      className="img-fluid"
                    />
                  </div>
                  <h4 className="title">
                    <Link to="">Affordable</Link>
                  </h4>
                  <p className="description">
                    We make sure that our hire developer services are a
                    cost-effective option for clients. On the other hand, hiring
                    a developer for a project or a temporary addition in your
                    team is more affordable than employing one.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0">
                <div
                  className="icon-box"
                  data-aos="fade-up"
                  data-aos-delay="400"
                >
                  <div className="icon">
                    <img
                      src="assets\img\FullControl-01.png"
                      alt="Image"
                      className="img-fluid"
                    />
                  </div>
                  <h4 className="title">
                    <Link to="">Full Control</Link>
                  </h4>
                  <p className="description">
                    Clients have complete control over the hired development
                    team until the project or specified dedicated hours. Hired
                    Java developers directly work with clients to plan, develop,
                    and deliver the product that they require.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0">
                <div
                  className="icon-box"
                  data-aos="fade-up"
                  data-aos-delay="400"
                >
                  <div className="icon">
                    <img
                      src="assets\img\FullTransparency-01.png"
                      alt="Image"
                      className="img-fluid"
                    />
                  </div>
                  <h4 className="title">
                    <Link to="">Full Transparency</Link>
                  </h4>
                  <p className="description">
                    AGM makes sure that clients receive complete transparency
                    from the dedicated developers to them. Also, transparency is
                    the main reason after quality, in satisfaction for our past
                    clients. This includes precise forecasting and prioritizing
                    stages of development as required.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0">
                <div
                  className="icon-box"
                  data-aos="fade-up"
                  data-aos-delay="400"
                >
                  <div className="icon">
                    <img
                      src="assets\img\QuickProcess-01.png"
                      alt="Image"
                      className="img-fluid"
                    />
                  </div>
                  <h4 className="title">
                    <Link to="">Quick Process</Link>
                  </h4>
                  <p className="description">
                    You don't need to interview a pool of candidates because
                    you'll be building your team virtually with us in no time.
                    This includes the flexibility of making changes in the
                    number of dedicated Java developers hired by you with just a
                    month's notice.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default Hirejavadev;

import React from "react";
import { Link } from "react-router-dom/cjs/react-router-dom";

class Hirecrossplatformdev extends React.Component {
  render() {
    return (
      <>
        <section id="breadcrumbs" className="breadcrumbs">
          <div className="container">
            <div className="d-flex justify-content-between align-items-center">
              <h2>Hire Cross Platform Developers</h2>
            </div>
          </div>
        </section>
        <div className="site-section">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 mb-4 mb-lg-0">
                <figure className="img-play-vid">
                  <img
                    src="assets/img/HIRE_Cross_Platform_App_Developer.png"
                    alt="Image"
                    className="img-fluid"
                  />
                </figure>
              </div>
              <div className="col-lg-6 ml-auto">
                <h2 className="mb-4 section-heading">Why AGM etech</h2>
                <p>
                  It is a fact that the Android operating system and apps have a
                  dominant market share. The Android sector is dynamic as well
                  as host to cutthroat competition. AGM solutions promise
                  advanced, unique, and customized android app development
                  services to the client, which will provide them with a
                  competitive edge in this sector. Our android app developers
                  are competent, from design to development to testing and
                  support. They promise end-to-end Android apps development
                  services. Android is an open-source and provides software
                  development kit (SDK) freely to the developers, minimizing the
                  development and licensing cost. The Android apps are also
                  fluent with the customization and can be easily integrated
                  with a technological stack like Java programming language and
                  others for better user experience and business needs. Our
                  Android programmers have expertise and experience for
                  developing apps for almost all App Store categories.
                </p>
              </div>
            </div>
          </div>
        </div>
        <section id="services" className="services">
          <div className="container">
            <div className="section-title" data-aos="fade-up">
              <h2>React.Js Developers Services</h2>
            </div>
            <div className="row">
              <div className="col-md-6 col-lg-6 d-flex align-items-stretch mb-5 mb-lg-0">
                <div
                  className="icon-box"
                  data-aos="fade-up"
                  data-aos-delay="100"
                >
                  <div className="icon">
                    {" "}
                    <img
                      src="assets\img\Cross-Platform-Consultation-01.png"
                      alt="Image"
                      className="img-fluid"
                    />
                  </div>
                  <h4 className="title">
                    <Link to="">Web Development</Link>
                  </h4>
                  <p className="description">
                    Expert React.Js developers to build a scalable & robust web
                    app for you. Share the idea and relax.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-6 d-flex align-items-stretch mb-5 mb-lg-0">
                <div
                  className="icon-box"
                  data-aos="fade-up"
                  data-aos-delay="200"
                >
                  <div className="icon">
                    <img
                      src="assets\img\AI-ML-Apps-01.png"
                      alt="Image"
                      className="img-fluid"
                    />
                  </div>
                  <h4 className="title">
                    <Link to="">CMS & Ecommerce Development</Link>
                  </h4>
                  <p className="description">
                    Today, enterprises are demanding React.Js performance for
                    their e-commerce venture needs. We have expert React.Js
                    developers to uplift your e-commerce & CMS development.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-6 d-flex align-items-stretch mb-5 mb-lg-0">
                <div
                  className="icon-box"
                  data-aos="fade-up"
                  data-aos-delay="300"
                >
                  <div className="icon">
                    <img
                      src="assets\img\UiUx-Development-01.png"
                      alt="Image"
                      className="img-fluid"
                    />
                  </div>
                  <h4 className="title">
                    <Link to="">Hosting & Setting Up Development Environment</Link>
                  </h4>
                  <p className="description">
                    CoodeIT has a pool of React.Js expert who can provide
                    cost-effective services for React.Js development environment
                    with various libraries & frameworks.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-6 d-flex align-items-stretch mb-5 mb-lg-0">
                <div
                  className="icon-box"
                  data-aos="fade-up"
                  data-aos-delay="400"
                >
                  <div className="icon">
                    <img
                      src="assets\img\maintenance-&-support-01.png"
                      alt="Image"
                      className="img-fluid"
                    />
                  </div>
                  <h4 className="title">
                    <Link to="">Front-End Development</Link>
                  </h4>
                  <p className="description">
                    Today enterprises also choose React.Js as it provides
                    flexibility and performance-oriented solutions to view MVC's
                    component.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="services" className="services">
          <div className="container">
            <div className="section-title" data-aos="fade-up">
              <h2>Favourable Hiring Models</h2>
            </div>
            <div className="row">
              <div className="col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0">
                <div
                  className="icon-box"
                  data-aos="fade-up"
                  data-aos-delay="100"
                >
                  <div className="icon">
                    {" "}
                    <img
                      src="assets\img\Full-TIme-01.png"
                      alt="Image"
                      className="img-fluid"
                    />
                  </div>
                  <h4 className="title">
                    <Link to="">Full-Time</Link>
                  </h4>
                  <p className="description">
                    The developer(s) will be dedicated to you for 8 hours a day
                    for five days a week. The billing is on a monthly basis,
                    with at least one month contract.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0">
                <div
                  className="icon-box"
                  data-aos="fade-up"
                  data-aos-delay="200"
                >
                  <div className="icon">
                    <img
                      src="assets\img\Part-TIme-01.png"
                      alt="Image"
                      className="img-fluid"
                    />
                  </div>
                  <h4 className="title">
                    <Link to="">Part-Time</Link>
                  </h4>
                  <p className="description">
                    Today, enterprises are demanding React.Js performance for
                    their e-commerce venture needs. We have expert React.Js
                    developers to uplift your e-commerce & CMS development.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0">
                <div
                  className="icon-box"
                  data-aos="fade-up"
                  data-aos-delay="300"
                >
                  <div className="icon">
                    <img
                      src="assets\img\Hourly-Basis-01.png"
                      alt="Image"
                      className="img-fluid"
                    />
                  </div>
                  <h4 className="title">
                    <Link to="">Hourly Basis</Link>
                  </h4>
                  <p className="description">
                    Developer will be dedicated to you for 8 hours a day for
                    five days a week, but working hours are on your disposal.
                    Billing will be monthly, total hours in the month x the
                    pre-decided hourly rate. Minimum contract of 25 hours.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0">
                <div
                  className="icon-box"
                  data-aos="fade-up"
                  data-aos-delay="400"
                >
                  <div className="icon">
                    <img
                      src="assets\img\Communication-01.png"
                      alt="Image"
                      className="img-fluid"
                    />
                  </div>
                  <h4 className="title">
                    <Link to="">Communication</Link>
                  </h4>
                  <p className="description">
                    The formal modes of communication with the developer will be
                    through email and skype.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0">
                <div
                  className="icon-box"
                  data-aos="fade-up"
                  data-aos-delay="400"
                >
                  <div className="icon">
                    <img
                      src="assets\img\Work.png"
                      alt="Image"
                      className="img-fluid"
                    />
                  </div>
                  <h4 className="title">
                    <Link to="">Work</Link>
                  </h4>
                  <p className="description">
                    Our developers believe in quality work, whether onsite or
                    offsite. Also, they are equally capable of product
                    development and can work in unique and innovative projects.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0">
                <div
                  className="icon-box"
                  data-aos="fade-up"
                  data-aos-delay="400"
                >
                  <div className="icon">
                    <img
                      src="assets\img\Focus-01.png"
                      alt="Image"
                      className="img-fluid"
                    />
                  </div>
                  <h4 className="title">
                    <Link to="">Focus</Link>
                  </h4>
                  <p className="description">
                    You can hire developers for a specified project or as an
                    addition to your team for a specified time.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default Hirecrossplatformdev;

import React from 'react';

class Contact extends React.Component {
    render() {
        return (
            <>
                <section id="breadcrumbs" className="breadcrumbs">
                    <div className="container">
                        <div className="d-flex justify-content-between align-items-center">
                            <h2>Get In Touch</h2>
                        </div>
                    </div>
                </section>

                <section id="portfolio-details" className="portfolio-details">
                    <div className="container">
                        <div className="row gy-4">
                            
                        </div>
                    </div>
                </section>

                <div className="site-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 ml-auto">
                                <h2 className="mb-4 section-heading">Are You Ready for Training?</h2>
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Itaque corporis doloribus consequatur fugit voluptatum ex rerum perspiciatis cupiditate, esse hic!</p>
                                <p className="mb-4">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptas, error!</p>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default Contact;
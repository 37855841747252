import React from "react";
import { Link } from "react-router-dom/cjs/react-router-dom";

class Contactus extends React.Component {
    render() {
        return (
            <>
                <section id="contact" className="contact">
                    <div className="container">
                        <div className="section-title" data-aos="fade-up">
                            <h2>Contact Us</h2>
                        </div>
                        <div className="row">
                            <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="100">
                                <div className="contact-about">
                                    <Link to="/Home"><img src="assets/img/Logo.png" alt="" className="img-fluid" /></Link>
                                    <p>Website Design & Development

                                        Customized Software

                                        Mobile Application Development

                                        Domain,Web Hosting & SMS Solutions

                                        Academic Training</p>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 mt-4 mt-md-0" data-aos="fade-up" data-aos-delay="200">
                                <div className="info">
                                    <div>
                                        <i className="ri-map-pin-line"></i>
                                        <p>429 S.V. Square Building,New Ranip, Ahmedabad, India</p>
                                    </div>
                                    <div>
                                        <i className="ri-mail-send-line"></i>
                                        <p>info@agmetech.com</p>
                                    </div>
                                    <div>
                                        <i className="ri-phone-line"></i>
                                        <p>+91 971 231 2188</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-5 col-md-12" data-aos="fade-up" data-aos-delay="300">
                                <form action="forms/contact.php" method="post" role="form" className="php-email-form">
                                    <div className="form-group">
                                        <input type="text" name="name" className="form-control" id="name" placeholder="Your Name" required />
                                    </div>
                                    <div className="form-group">
                                        <input type="email" className="form-control" name="email" id="email" placeholder="Your Email" required />
                                    </div>
                                    <div className="form-group">
                                        <input type="text" className="form-control" name="subject" id="subject" placeholder="Subject" required />
                                    </div>
                                    <div className="form-group">
                                        <textarea className="form-control" name="message" rows="5" placeholder="Message" required></textarea>
                                    </div>
                                    <div className="my-3">
                                        <div className="loading">Loading</div>
                                        <div className="error-message"></div>
                                        <div className="sent-message">Your message has been sent. Thank you!</div>
                                    </div>
                                    <div className="text-center"><button type="submit">Send Message</button></div>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}

export default Contactus;
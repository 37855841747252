import React, { Component } from 'react';
import { Link } from 'react-router-dom/cjs/react-router-dom';

class Footer extends Component {
  render() {
    return (
      <footer id="footer">
        <div className="container">
          <div className="row d-flex align-items-center">
            <div className="col-lg-6">
              <div className="copyright">
                &copy; Copyright <strong>Agm Etech Private Limited</strong>. All Rights Reserved
              </div>
              <div className="credits">
              </div>
            </div>
            <div className="col-lg-6">
              <nav className="footer-links text-lg-right text-center pt-2 pt-lg-0">
                <Link to="/Home" className="scrollto">Home</Link>
                <Link to="/Services" className="scrollto">Services</Link>
                <Link to="/HireDevelopers">Hire Developers</Link>
                <Link to="/Portfolioin">Portfolio</Link>
                <Link to="/Contact">Contact Us</Link>
                <Link to="/Aboutus">About Us</Link>
              </nav>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
import React from "react";
import { Link } from "react-router-dom/cjs/react-router-dom";

class Androidappdevelopment extends React.Component {
  render() {
    return (
      <>
        <section id="breadcrumbs" className="breadcrumbs">
          <div className="container">
            <div className="d-flex justify-content-between align-items-center">
              <h2>Android App Development</h2>
            </div>
          </div>
        </section>

        <div className="site-section">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 mb-4 mb-lg-0">
                <figure className="img-play-vid">
                  <img
                    src="assets/img/Android_1.png"
                    alt="Image"
                    className="img-fluid"
                  />
                </figure>
              </div>
              <div className="col-lg-6 ml-auto">
                <h2 className="mb-4 section-heading">Why AGM eTech</h2>
                <p>
                  It is a fact that the Android operating system and apps have a
                  dominant market share. The Android sector is dynamic as well
                  as host to cutthroat competition. AGM solutions promise
                  advanced, unique, and customized android app development
                  services to the client, which will provide them with a
                  competitive edge in this sector. Our android app developers
                  are competent, from design to development to testing and
                  support. They promise end-to-end Android apps development
                  services. Android is an open-source and provides software
                  development kit (SDK) freely to the developers, minimizing the
                  development and licensing cost. The Android apps are also
                  fluent with the customization and can be easily integrated
                  with a technological stack like Java programming language and
                  others for better user experience and business needs. Our
                  Android programmers have expertise and experience for
                  developing apps for almost all App Store categories.
                </p>
              </div>
            </div>
          </div>
        </div>

        <section id="services" className="services">
          <div className="container">
            <div className="section-title" data-aos="fade-up">
              <h2>Our iOS Developers Provide</h2>
            </div>
            <div className="row">
              <div className="col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0">
                <div
                  className="icon-box"
                  data-aos="fade-up"
                  data-aos-delay="100"
                >
                  <div className="icon">
                    <img
                      src="assets\img\Full-TIme-01.png"
                      alt="Image"
                      className="img-fluid"
                    />
                  </div>
                  <h4 className="title">
                    <Link to="">High Expertise</Link>
                  </h4>
                  <p className="description">
                    TWe always focus and emphasize developing and expanding the
                    skill set of our team and organization as a whole—iOS
                    demands for advancement and dynamicity in every developer.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0">
                <div
                  className="icon-box"
                  data-aos="fade-up"
                  data-aos-delay="200"
                >
                  <div className="icon">
                    <img
                      src="assets\img\Part-TIme-01.png"
                      alt="Image"
                      className="img-fluid"
                    />
                  </div>
                  <h4 className="title">
                    <Link to="">Effective</Link>
                  </h4>
                  <p className="description">
                    We understand that time is money, and in the IT industry,
                    the faster your work, and the quicker you launch, the more
                    successful you can get. Our team is committed to providing
                    quality results within the decided deadlines.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0"></div>
              <div className="col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0">
                <div
                  className="icon-box"
                  data-aos="fade-up"
                  data-aos-delay="400"
                >
                  <div className="icon">
                    <img
                      src="assets\img\FullControl-01.png"
                      alt="Image"
                      className="img-fluid"
                    />
                  </div>
                  <h4 className="title">
                    <Link to="">Diverse Options</Link>
                  </h4>
                  <p className="description">
                    Our team is well versed in Object C and Swift iOS
                    technology. We can also develop apps for iPhone, iPad, Apple
                    TV, Apple Watch, and more, with the best design and
                    necessary customization.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0">
                <div
                  className="icon-box"
                  data-aos="fade-up"
                  data-aos-delay="400"
                >
                  <div className="icon">
                    <img
                      src="assets\img\FullTransparency-01.png"
                      alt="Image"
                      className="img-fluid"
                    />
                  </div>
                  <h4 className="title">
                    <Link to="">Diverse Experience</Link>
                  </h4>
                  <p className="description">
                    Our developers have worked for various businesses in
                    different industries. Along with app development services,
                    they are also experienced in finding and revamping a
                    dysfunctioning app.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0"></div>
            </div>
          </div>
        </section>

        <section id="services" className="services">
          <div className="container">
            <div className="section-title" data-aos="fade-up">
              <h2>Services In iOS App Development</h2>
            </div>
            <div className="row">
              <div className="col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0">
                <div className="icon-box">
                  <div className="icon">
                    <img
                      src="assets\img\Full-TIme-01.png"
                      alt="Image"
                      className="img-fluid"
                    />
                  </div>
                  <h4 className="title">
                    <Link to="">Understanding Your Idea</Link>
                  </h4>
                  <p className="description">
                    We start by designing the necessary prototypes, wireframes,
                    and mock-ups. Here we will provide you some options to
                    choose from. As you select one, we will move to the coding
                    cycle.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0">
                <div
                  className="icon-box"
                  data-aos="fade-up"
                  data-aos-delay="200"
                >
                  <div className="icon">
                    <img
                      src="assets\img\Part-TIme-01.png"
                      alt="Image"
                      className="img-fluid"
                    />
                  </div>
                  <h4 className="title">
                    <Link to="">Realizing Your Idea</Link>
                  </h4>
                  <p className="description">
                    We promise a high-quality coding cycle and expert software
                    testing so that the app is bug-free & highly responsive.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0">
                <div
                  className="icon-box"
                  data-aos="fade-up"
                  data-aos-delay="300"
                >
                  <div className="icon">
                    <img
                      src="assets\img\Affordable-01.png"
                      alt="Image"
                      className="img-fluid"
                    />
                  </div>
                  <h4 className="title">
                    <Link to="">Smart iOS Apps</Link>
                  </h4>
                  <p className="description">
                    If needed, our developers are skilled enough to properly
                    integrate your iOS app with smart technology like artificial
                    intelligence and more.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0">
                <div
                  className="icon-box"
                  data-aos="fade-up"
                  data-aos-delay="400"
                >
                  <div className="icon">
                    <img
                      src="assets\img\FullControl-01.png"
                      alt="Image"
                      className="img-fluid"
                    />
                  </div>
                  <h4 className="title">
                    <Link to="">Third-party API Integration</Link>
                  </h4>
                  <p className="description">
                    As per the requirement, the developed iOS apps can also be
                    integrated with third-party APIs for easier access to data
                    and other benefits.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0">
                <div
                  className="icon-box"
                  data-aos="fade-up"
                  data-aos-delay="400"
                >
                  <div className="icon">
                    <img
                      src="assets\img\FullTransparency-01.png"
                      alt="Image"
                      className="img-fluid"
                    />
                  </div>
                  <h4 className="title">
                    <Link to="">Cloud Development</Link>
                  </h4>
                  <p className="description">
                    Today users prefer the apps that can connect with the cloud.
                    This provides them extra space and easier access to data.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0">
                <div
                  className="icon-box"
                  data-aos="fade-up"
                  data-aos-delay="400"
                >
                  <div className="icon">
                    <img
                      src="assets\img\QuickProcess-01.png"
                      alt="Image"
                      className="img-fluid"
                    />
                  </div>
                  <h4 className="title">
                    <Link to="">Post-App Delivery</Link>
                  </h4>
                  <p className="description">
                    Post-delivery, every app demands regular maintenance and
                    up-gradation; we also provide contracts for the same.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default Androidappdevelopment;

import React from "react";

class Aboutus extends React.Component {
    render() {
        return (
            <>
                <section id="breadcrumbs" className="breadcrumbs">
                    <div className="container">
                        
                    </div>
                </section>

                <section id="about" className="about">
                    <div className="container">
                        <div className="section-title" data-aos="fade-up">
                            <h2>About Us</h2>
                        </div>
                        <div className="row content">
                            <div className="col-lg-12" data-aos="fade-up" data-aos-delay="150">
                                <p>
                                    Agm etech is a India-based software development company with offices in Ahmedabad, Gujarat India .Our highly talented and dedicated staff deliver work of exceptional quality, and can help you no matter what stage you are at in developing your software. We are totally committed to customer satisfaction, and this drives everything we do.
                                </p>
                                <div className="col-lg-12" data-aos="fade-up" data-aos-delay="300">
                                    <p>
                                        <h5>WHO WE ARE</h5>
                                        AGM ETECH was started  as name Anantay IT Solutions in Ahmedabad-India in 2015. From 26th June 2021 We change name from Anantay IT Solutions to AGM Etech Private Limited. 
                                    </p>
                                    <ul>
                                        <h5>WE OFFER OUR CUSTOMERS</h5>
                                        <li><i className="ri-check-double-line"></i> Outstanding Customer Service</li>
                                        <li><i className="ri-check-double-line"></i> A Complete Understanding of Their Business Issues</li>
                                        <li><i className="ri-check-double-line"></i> Intelligent Ideas</li>
                                        <li><i className="ri-check-double-line"></i> Fast and Flexible Turnaround</li>
                                        <li><i className="ri-check-double-line"></i> Success – Guaranteed Every Time</li>
                                    </ul>
                                </div>
                             
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}

export default Aboutus;
import React from "react";
import { Link } from "react-router-dom/cjs/react-router-dom";

function Servicebox(props) {
  return (
    <section id="services" className="services">
      <div className="container">
        <div className="section-title" data-aos="fade-up">
          <h2>Services</h2>
        </div>
        <div className="row">
          <div className="col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0">
            <div className="icon-box" data-aos="fade-up" data-aos-delay="100">
              <div className="icon">
                <i className="bx bxl-dribbble"></i>
              </div>
              <h4 className="title">
                <Link to="">Website Design</Link>
              </h4>
              <p className="description">
                Now days, to stay in competition of business you need provide
                your service 24 hours.Website of business fullfill 24 hours
                service.We design website as per customer's requirement with
                fully responsive.
              </p>
            </div>
          </div>
          <div className="col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0">
            <div className="icon-box" data-aos="fade-up" data-aos-delay="200">
              <div className="icon">
                <i className="bx bx-file"></i>
              </div>
              <h4 className="title">
                <Link to="/Webappdev">Web Application Development</Link>
              </h4>
              <p className="description">
                Become more powerfull business, need decrease communicaiton gap
                in business for that business need online Web application.We
                provide web application with all solutions of customer
              </p>
            </div>
          </div>
          <div className="col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0">
            <div className="icon-box" data-aos="fade-up" data-aos-delay="300">
              <div className="icon">
                <i className="bx bx-tachometer"></i>
              </div>
              <h4 className="title">
                <Link to="/Customersoftwaredev">
                  Customized Software Development
                </Link>
              </h4>
              <p className="description">
                Every business need software online or offline for manage their
                organisation.We make customized software as per customer
                requirement
              </p>
            </div>
          </div>
          <div className="col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0">
            <div className="icon-box" data-aos="fade-up" data-aos-delay="400">
              <div className="icon">
                <i className="bx bx-world"></i>
              </div>
              <h4 className="title">
                <Link to="/Androidappdevelopment">
                  Mobile Application Development
                </Link>
              </h4>
              <p className="description">
                Mobile application development is similar to Web application
                development and has its roots in more traditional software
                development. One critical difference, however, is that mobile
                applications (apps) are often written specifically to take
                advantage of the unique features a particular mobile device
                offers.
              </p>
            </div>
          </div>
          <div className="col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0">
            <div className="icon-box" data-aos="fade-up" data-aos-delay="400">
              <div className="icon">
                <i className="bx bx-world"></i>
              </div>
              <h4 className="title">
                <Link to="">SEO (Search Engine Optimization)</Link>
              </h4>
              <p className="description">
                An SEO service provider utilizes the practice of search engine
                optimization to increase the amount of visitors to a Web site by
                obtaining high-ranking placements in the search results page of
                search engines (SERP). Typically, a business will hire a service
                provider to improve its organic Google search result listings.
              </p>
            </div>
          </div>
          <div className="col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0">
            <div className="icon-box" data-aos="fade-up" data-aos-delay="400">
              <div className="icon">
                <i className="bx bx-world"></i>
              </div>
              <h4 className="title">
                <Link to="">Domain Registration & Web Hosting</Link>
              </h4>
              <p className="description">
                Mobile application development is similar to Web application
                development and has its roots in more traditional software
                development. One critical difference, however, is that mobile
                applications (apps) are often written specifically to take
                advantage of the unique features a particular mobile device
                offers.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Servicebox;

import React from "react";
import { Link } from "react-router-dom/cjs/react-router-dom";

class Iosappdevelopment extends React.Component {
  render() {
    return (
      <>
        <section id="breadcrumbs" className="breadcrumbs">
          <div className="container">
            <div className="d-flex justify-content-between align-items-left">
              <h2>iOS App development</h2>
            </div>
          </div>
        </section>

        <div className="site-section">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 mb-4 mb-lg-0">
                <figure className="img-play-vid">
                  <img
                    src="assets/img/iOS.png"
                    alt="Image"
                    className="img-fluid"
                  />
                </figure>
              </div>
              <div className="col-lg-6 ml-auto">
                <h2 className="mb-4 section-heading">Why AGM eTech</h2>
                <p>
                  iOS is among the most advanced application development
                  platforms. Worldwide, there are millions of iOS supported apps
                  or native iOS apps on the App store. This number is increasing
                  with each passing day. We have a team of expert and
                  experienced strategists and full-stack iPhone app developers
                  for this competitive and dynamic environment. We make sure
                  that our team is aware, and in compliance with the latest iOS
                  updates, so the apps are as per the latest iOS SDK frameworks
                  and deliver comprehensive iOS app development services. We
                  meet the client's expectations by developing business-centric
                  iOS apps that help enterprises stay ahead. For this, our team
                  has in-depth and proven technical expertise. We believe that
                  apps are valuable and satisfying when developers are dedicated
                  and committed from designing to the launch, every step of the
                  iOS application development lifecycle demands a developer to
                  invest their full efforts with high sincerity.
                </p>
              </div>
            </div>
          </div>
        </div>

        <section id="services" className="services">
          <div className="container">
            <div className="section-title" data-aos="fade-up">
              <h2>Our iOS Developers Provide</h2>
            </div>
            <div className="row">
              <div className="col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0">
                <div
                  className="icon-box"
                  data-aos="fade-up"
                  data-aos-delay="100"
                >
                  <div className="icon">
                    <img
                      src="assets\img\Full-TIme-01.png"
                      alt="Image"
                      className="img-fluid"
                    />
                  </div>
                  <h4 className="title">
                    <Link to="">High Expertise</Link>
                  </h4>
                  <p className="description">
                    TWe always focus and emphasize developing and expanding the
                    skill set of our team and organization as a whole—iOS
                    demands for advancement and dynamicity in every developer.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0">
                <div
                  className="icon-box"
                  data-aos="fade-up"
                  data-aos-delay="200"
                >
                  <div className="icon">
                    <img
                      src="assets\img\Part-TIme-01.png"
                      alt="Image"
                      className="img-fluid"
                    />
                  </div>
                  <h4 className="title">
                    <Link to="">Effective</Link>
                  </h4>
                  <p className="description">
                    We understand that time is money, and in the IT industry,
                    the faster your work, and the quicker you launch, the more
                    successful you can get. Our team is committed to providing
                    quality results within the decided deadlines.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0"></div>
              <div className="col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0">
                <div
                  className="icon-box"
                  data-aos="fade-up"
                  data-aos-delay="400"
                >
                  <div className="icon">
                    <img
                      src="assets\img\FullControl-01.png"
                      alt="Image"
                      className="img-fluid"
                    />
                  </div>
                  <h4 className="title">
                    <Link to="">Diverse Options</Link>
                  </h4>
                  <p className="description">
                    Our team is well versed in Object C and Swift iOS
                    technology. We can also develop apps for iPhone, iPad, Apple
                    TV, Apple Watch, and more, with the best design and
                    necessary customization.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0">
                <div
                  className="icon-box"
                  data-aos="fade-up"
                  data-aos-delay="400"
                >
                  <div className="icon">
                    <img
                      src="assets\img\FullTransparency-01.png"
                      alt="Image"
                      className="img-fluid"
                    />
                  </div>
                  <h4 className="title">
                    <Link to="">Diverse Experience</Link>
                  </h4>
                  <p className="description">
                    Our developers have worked for various businesses in
                    different industries. Along with app development services,
                    they are also experienced in finding and revamping a
                    dysfunctioning app.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0"></div>
            </div>
          </div>
        </section>

        <section id="services" className="services">
          <div className="container">
            <div className="section-title" data-aos="fade-up">
              <h2>Services In iOS App Development</h2>
            </div>
            <div className="row">
              <div className="col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0">
                <div className="icon-box">
                  <div className="icon">
                    <img
                      src="assets\img\Full-TIme-01.png"
                      alt="Image"
                      className="img-fluid"
                    />
                  </div>
                  <h4 className="title">
                    <Link to="">Understanding Your Idea</Link>
                  </h4>
                  <p className="description">
                    We start by designing the necessary prototypes, wireframes,
                    and mock-ups. Here we will provide you some options to
                    choose from. As you select one, we will move to the coding
                    cycle.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0">
                <div
                  className="icon-box"
                  data-aos="fade-up"
                  data-aos-delay="200"
                >
                  <div className="icon">
                    <img
                      src="assets\img\Part-TIme-01.png"
                      alt="Image"
                      className="img-fluid"
                    />
                  </div>
                  <h4 className="title">
                    <Link to="">Realizing Your Idea</Link>
                  </h4>
                  <p className="description">
                    We promise a high-quality coding cycle and expert software
                    testing so that the app is bug-free & highly responsive.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0">
                <div
                  className="icon-box"
                  data-aos="fade-up"
                  data-aos-delay="300"
                >
                  <div className="icon">
                    <img
                      src="assets\img\Affordable-01.png"
                      alt="Image"
                      className="img-fluid"
                    />
                  </div>
                  <h4 className="title">
                    <Link to="">Smart iOS Apps</Link>
                  </h4>
                  <p className="description">
                    If needed, our developers are skilled enough to properly
                    integrate your iOS app with smart technology like artificial
                    intelligence and more.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0">
                <div
                  className="icon-box"
                  data-aos="fade-up"
                  data-aos-delay="400"
                >
                  <div className="icon">
                    <img
                      src="assets\img\FullControl-01.png"
                      alt="Image"
                      className="img-fluid"
                    />
                  </div>
                  <h4 className="title">
                    <Link to="">Third-party API Integration</Link>
                  </h4>
                  <p className="description">
                    As per the requirement, the developed iOS apps can also be
                    integrated with third-party APIs for easier access to data
                    and other benefits.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0">
                <div
                  className="icon-box"
                  data-aos="fade-up"
                  data-aos-delay="400"
                >
                  <div className="icon">
                    <img
                      src="assets\img\FullTransparency-01.png"
                      alt="Image"
                      className="img-fluid"
                    />
                  </div>
                  <h4 className="title">
                    <Link to="">Cloud Development</Link>
                  </h4>
                  <p className="description">
                    Today users prefer the apps that can connect with the cloud.
                    This provides them extra space and easier access to data.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0">
                <div
                  className="icon-box"
                  data-aos="fade-up"
                  data-aos-delay="400"
                >
                  <div className="icon">
                    <img
                      src="assets\img\QuickProcess-01.png"
                      alt="Image"
                      className="img-fluid"
                    />
                  </div>
                  <h4 className="title">
                    <Link to="">Post-App Delivery</Link>
                  </h4>
                  <p className="description">
                    Post-delivery, every app demands regular maintenance and
                    up-gradation; we also provide contracts for the same.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default Iosappdevelopment;

import React from "react";
import { Link } from "react-router-dom/cjs/react-router-dom";

class Webandcmsdev extends React.Component {
  render() {
    return (
      <>
        <section id="breadcrumbs" className="breadcrumbs">
          <div className="container">
            <div className="d-flex justify-content-between align-items-center">
              <h2>Web & CMS Development</h2>
            </div>
          </div>
        </section>

        <div className="site-section">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 mb-4 mb-lg-0">
                <figure className="img-play-vid">
                  <img
                    src="assets/img/web_CMS.png"
                    alt="Image"
                    className="img-fluid"
                  />
                </figure>
              </div>
              <div className="col-lg-6 ml-auto">
                <h2 className="mb-4 section-heading">Why AGM eTech</h2>
                <p>
                  We believe in providing eCommerce development services that
                  help clients with digital applications of such a platform and
                  provide them with a sharp competitive edge. Our web developers
                  match the industry standard and current technological
                  innovations to provide you with the best and most useful web
                  services and solutions. Our team has experience serving
                  eCommerce business solutions to clients with the complete
                  portal, shopping cart, and product solutions, including a
                  secure payment gateway integration. We make sure that each
                  feature we integrate makes running your eCommerce business
                  smoothly. We make sure that our clients' end-users, who are
                  our client's consumers, receive the best shopping experience.
                  Our team is capable of designing a competitive and attractive
                  full-fledged online eCommerce store web design and custom
                  eCommerce web development for small, medium, and large-scale
                  businesses.
                </p>
              </div>
            </div>
          </div>
        </div>

        <section id="services" className="services">
          <div className="container">
            <div className="section-title" data-aos="fade-up">
              <h2>What Makes Us Different</h2>
            </div>
            <div className="row">
              <div className="col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0">
                <div
                  className="icon-box"
                  data-aos="fade-up"
                  data-aos-delay="100"
                >
                  <div className="icon">
                    <img
                      src="assets\img\Full-TIme-01.png"
                      alt="Image"
                      className="img-fluid"
                    />
                  </div>
                  <h4 className="title">
                    <Link to="">Customizable Websites</Link>
                  </h4>
                  <p className="description">
                    Our expert and experienced web developers can take
                    customization to the next level for you. The websites will
                    be responsive and flexible. We will also suggest what's new
                    and what may/may not work for you. Apart from this, we also
                    plan for future enhancements to deliver even more value to
                    the end-users.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0">
                <div
                  className="icon-box"
                  data-aos="fade-up"
                  data-aos-delay="200"
                >
                  <div className="icon">
                    <img
                      src="assets\img\Part-TIme-01.png"
                      alt="Image"
                      className="img-fluid"
                    />
                  </div>
                  <h4 className="title">
                    <Link to="">Efficient Process</Link>
                  </h4>
                  <p className="description">
                    Our web developers will look for what's necessary plugins in
                    the back and server, and keep only them. The new plugins and
                    current trending ones are also added as per your need. Our
                    web developers also follow an agile approach to optimize
                    every website's performance and functionality.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0"></div>
              <div className="col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0">
                <div
                  className="icon-box"
                  data-aos="fade-up"
                  data-aos-delay="400"
                >
                  <div className="icon">
                    <img
                      src="assets\img\FullControl-01.png"
                      alt="Image"
                      className="img-fluid"
                    />
                  </div>
                  <h4 className="title">
                    <Link to="">Optimized Website</Link>
                  </h4>
                  <p className="description">
                    Our developers promise fast loading, SEO friendly,
                    AMP-enabled, and mobile-accessible websites that are also
                    optimized in functionality. They can also make WordPress
                    management simple for you. With us, you don't need to worry
                    about traffic spears, server loading time, content
                    management, and smooth running.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0">
                <div
                  className="icon-box"
                  data-aos="fade-up"
                  data-aos-delay="400"
                >
                  <div className="icon">
                    <img
                      src="assets\img\FullTransparency-01.png"
                      alt="Image"
                      className="img-fluid"
                    />
                  </div>
                  <h4 className="title">
                    <Link to="">Expert Skills</Link>
                  </h4>
                  <p className="description">
                    Our developers boast experience in developing blogs,
                    templates, business sites, and eCommerce websites. They are
                    also expert enough to create their own theme with extended
                    plugins that best suit your business activities. We will
                    also add Google's AMP Support to make sure that your web
                    pages pages load faster.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0"></div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default Webandcmsdev;

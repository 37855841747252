import React from "react";
import { Link } from "react-router-dom/cjs/react-router-dom";

class Webappdev extends React.Component {
  render() {
    return (
      <>
        <section id="breadcrumbs" className="breadcrumbs">
          <div className="container">
            <div className="d-flex justify-content-between align-items-center">
              <h2>Android App Development</h2>
            </div>
          </div>
        </section>

        <section id="portfolio-details" className="portfolio-details">
          <div className="container">
            <div className="row gy-4"></div>
          </div>
        </section>

        <div className="site-section">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 mb-4 mb-lg-0">
                <figure className="img-play-vid">
                  <img
                    src="assets/img/web.png"
                    alt="Image"
                    className="img-fluid"
                  />
                </figure>
              </div>
              <div className="col-lg-6 ml-auto">
                <h2 className="mb-4 section-heading">
                  Web Application Development
                </h2>
                <p>
                  Our custom web application development services hold a
                  distinct position in the market. Clients come first for us,
                  and with our experience and expertise in the IT industry, we
                  provide various solutions and suggestions to clients that go
                  beyond just web apps & PWAs. Clients look for ways to automate
                  their business processes and bridge complex digital gaps via
                  web apps and PWAs. We make sure that the end products we hand
                  over to clients stand in terms with high scalability,
                  usability, and compatibility. Our services approach is also
                  inclusive of client's consumers, who will be end-users of our
                  products, ensuring the quality user experience is also one of
                  our aims. Our developers are experts in creating Web apps and
                  PWAs that consistently and smoothly function across the
                  different browsers and devices. From the initial contact to
                  post-delivery maintenance, we are there with you through
                  honest and transparent communication. Our team will be in
                  touch with you throughout the planning, actual development,
                  and maintenance.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="site-section">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 mb-4 mb-lg-0"></div>
              <div className="col-lg-6 ml-auto">
                <h2 className="mb-4 section-heading">Web Apps & PWAs</h2>
                <p>
                  Web App, in simple terms, is a website designed for mobiles.
                  It makes the website optimized and accessible through
                  different mobiles by making the content fit to the device
                  screen. It also has some features and functionalities of
                  native-device. Progressive Web App, as the name suggests, is a
                  type of regular web app but with more advanced features to
                  deliver a better user experience. It can be termed as a
                  perfect combination of desktop and mobile application
                  experience. The user gets the best of both the platform's
                  experience. At AGM, it is essential to suggest and provide
                  options to our clients for their idea. We will help you choose
                  the best one based on your future plans, business model,
                  requirement, budget, and other factors.
                </p>
              </div>
            </div>
          </div>
        </div>

        <section id="services" className="services">
          <div className="container">
            <div className="section-title" data-aos="fade-up">
              <h2>What Makes Us Different</h2>
            </div>
            <div className="row">
              <div className="col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0">
                <div
                  className="icon-box"
                  data-aos="fade-up"
                  data-aos-delay="100"
                >
                  <div className="icon">
                    <img
                      src="assets\img\Full-TIme-01.png"
                      alt="Image"
                      className="img-fluid"
                    />
                  </div>
                  <h4 className="title">
                    <Link to="">Customizable Websites</Link>
                  </h4>
                  <p className="description">
                    Our expert and experienced web developers can take
                    customization to the next level for you. The websites will
                    be responsive and flexible. We will also suggest what's new
                    and what may/may not work for you. Apart from this, we also
                    plan for future enhancements to deliver even more value to
                    the end-users.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0">
                <div
                  className="icon-box"
                  data-aos="fade-up"
                  data-aos-delay="200"
                >
                  <div className="icon">
                    <img
                      src="assets\img\Part-TIme-01.png"
                      alt="Image"
                      className="img-fluid"
                    />
                  </div>
                  <h4 className="title">
                    <Link to="">Efficient Process</Link>
                  </h4>
                  <p className="description">
                    Our web developers will look for what's necessary plugins in
                    the back and server, and keep only them. The new plugins and
                    current trending ones are also added as per your need. Our
                    web developers also follow an agile approach to optimize
                    every website's performance and functionality.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0"></div>
              <div className="col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0">
                <div
                  className="icon-box"
                  data-aos="fade-up"
                  data-aos-delay="400"
                >
                  <div className="icon">
                    <img
                      src="assets\img\FullControl-01.png"
                      alt="Image"
                      className="img-fluid"
                    />
                  </div>
                  <h4 className="title">
                    <Link to="">Optimized Website</Link>
                  </h4>
                  <p className="description">
                    Our developers promise fast loading, SEO friendly,
                    AMP-enabled, and mobile-accessible websites that are also
                    optimized in functionality. They can also make WordPress
                    management simple for you. With us, you don't need to worry
                    about traffic spears, server loading time, content
                    management, and smooth running.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0">
                <div
                  className="icon-box"
                  data-aos="fade-up"
                  data-aos-delay="400"
                >
                  <div className="icon">
                    <img
                      src="assets\img\FullTransparency-01.png"
                      alt="Image"
                      className="img-fluid"
                    />
                  </div>
                  <h4 className="title">
                    <Link to="">Expert Skills</Link>
                  </h4>
                  <p className="description">
                    Our developers boast experience in developing blogs,
                    templates, business sites, and eCommerce websites. They are
                    also expert enough to create their own theme with extended
                    plugins that best suit your business activities. We will
                    also add Google's AMP Support to make sure that your web
                    pages pages load faster.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0"></div>
            </div>
          </div>
        </section>

        <section id="services" className="services">
          <div className="container">
            <div className="section-title" data-aos="fade-up">
              <h2>Services In iOS App Development</h2>
            </div>
            <div className="row">
              <div className="col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0">
                <div className="icon-box">
                  <div className="icon">
                    <img
                      src="assets\img\Full-TIme-01.png"
                      alt="Image"
                      className="img-fluid"
                    />
                  </div>
                  <h4 className="title">
                    <Link to="">Understanding Your Idea</Link>
                  </h4>
                  <p className="description">
                    We start by designing the necessary prototypes, wireframes,
                    and mock-ups. Here we will provide you some options to
                    choose from. As you select one, we will move to the coding
                    cycle.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0">
                <div
                  className="icon-box"
                  data-aos="fade-up"
                  data-aos-delay="200"
                >
                  <div className="icon">
                    <img
                      src="assets\img\Part-TIme-01.png"
                      alt="Image"
                      className="img-fluid"
                    />
                  </div>
                  <h4 className="title">
                    <Link to="">Realizing Your Idea</Link>
                  </h4>
                  <p className="description">
                    We promise a high-quality coding cycle and expert software
                    testing so that the app is bug-free & highly responsive.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0">
                <div
                  className="icon-box"
                  data-aos="fade-up"
                  data-aos-delay="300"
                >
                  <div className="icon">
                    <img
                      src="assets\img\Affordable-01.png"
                      alt="Image"
                      className="img-fluid"
                    />
                  </div>
                  <h4 className="title">
                    <Link to="">Smart iOS Apps</Link>
                  </h4>
                  <p className="description">
                    If needed, our developers are skilled enough to properly
                    integrate your iOS app with smart technology like artificial
                    intelligence and more.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0">
                <div
                  className="icon-box"
                  data-aos="fade-up"
                  data-aos-delay="400"
                >
                  <div className="icon">
                    <img
                      src="assets\img\FullControl-01.png"
                      alt="Image"
                      className="img-fluid"
                    />
                  </div>
                  <h4 className="title">
                    <Link to="">Third-party API Integration</Link>
                  </h4>
                  <p className="description">
                    As per the requirement, the developed iOS apps can also be
                    integrated with third-party APIs for easier access to data
                    and other benefits.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0">
                <div
                  className="icon-box"
                  data-aos="fade-up"
                  data-aos-delay="400"
                >
                  <div className="icon">
                    <img
                      src="assets\img\FullTransparency-01.png"
                      alt="Image"
                      className="img-fluid"
                    />
                  </div>
                  <h4 className="title">
                    <Link to="">Cloud Development</Link>
                  </h4>
                  <p className="description">
                    Today users prefer the apps that can connect with the cloud.
                    This provides them extra space and easier access to data.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0">
                <div
                  className="icon-box"
                  data-aos="fade-up"
                  data-aos-delay="400"
                >
                  <div className="icon">
                    <img
                      src="assets\img\QuickProcess-01.png"
                      alt="Image"
                      className="img-fluid"
                    />
                  </div>
                  <h4 className="title">
                    <Link to="">Post-App Delivery</Link>
                  </h4>
                  <p className="description">
                    Post-delivery, every app demands regular maintenance and
                    up-gradation; we also provide contracts for the same.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default Webappdev;

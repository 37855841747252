import React from "react";
import { Link } from "react-router-dom/cjs/react-router-dom";

function HireDevelopers() {
  return (
    <>
      <section id="breadcrumbs" className="breadcrumbs">
        <div className="container"></div>
      </section>
      <section id="services" className="services">
        <div className="container">
          <div className="section-title" data-aos="fade-up">
            <h2>Hire Developers</h2>
          </div>
          <div className="row">
            <div className="col-md-6 col-lg-6 d-flex align-items-stretch mb-5 mb-lg-0">
              <div className="icon-box" data-aos="fade-up" data-aos-delay="100">
                <div className="icon">
                  <i className="bx bxl-dribbble"></i>
                </div>
                <h4 className="title">
                  <Link to="">Hire Nodejs Developers</Link>
                </h4>
                <p className="description">
                  AGM promises it's clients to provide expert Node.Js developers
                  for hire. When there's a team behind an idea, the work gets
                  fast, smooth, and often simple. We believe in providing
                  effective hire Node Js developer services.
                </p>
              </div>
            </div>
            <div className="col-md-6 col-lg-6 d-flex align-items-stretch mb-5 mb-lg-0">
              <div className="icon-box" data-aos="fade-up" data-aos-delay="200">
                <div className="icon">
                  <i className="bx bx-file"></i>
                </div>
                <h4 className="title">
                  <Link to="">Hire React Js Developers</Link>
                </h4>
                <p className="description">
                  AGM is proud to be among the first options for the enterprises
                  to look for React.Js Developers for hire. We assure our
                  clients with quality React.Js services and a committed help
                  right from selecting a developer of your choice to the final
                  product delivery.
                </p>
              </div>
            </div>
            <div className="col-md-6 col-lg-6 d-flex align-items-stretch mb-5 mb-lg-0">
              <div className="icon-box" data-aos="fade-up" data-aos-delay="300">
                <div className="icon">
                  <i className="bx bx-tachometer"></i>
                </div>
                <h4 className="title">
                  <Link to="">Hire Cross Platform Developers</Link>
                </h4>
                <p className="description">
                  Mobile apps are the trend and will be for a long time. As
                  there's the rise of different platforms and mediums, the
                  demand for apps that have compatibility with various devices
                  and mediums have risen. At AGM, we have a pool of
                  cross-platform developers for hire.
                </p>
              </div>
            </div>
            <div className="col-md-6 col-lg-6 d-flex align-items-stretch mb-5 mb-lg-0">
              <div className="icon-box" data-aos="fade-up" data-aos-delay="400">
                <div className="icon">
                  <i className="bx bx-world"></i>
                </div>
                <h4 className="title">
                  <Link to="">Hire Java Developers</Link>
                </h4>
                <p className="description">
                  AGM has earned a reputation of having expert Java developers
                  who provide quality services to the clients. Today, when java
                  development is widespread and highly used, clients require
                  high-quality and expert Java developers who possess in-depth
                  know-how of the robust Java frameworks and related applied
                  technologies.
                </p>
              </div>
            </div>
            <div className="col-md-6 col-lg-6 d-flex align-items-stretch mb-5 mb-lg-0">
              <div className="icon-box" data-aos="fade-up" data-aos-delay="400">
                <div className="icon">
                  <i className="bx bx-world"></i>
                </div>
                <h4 className="title">
                  <Link to="">Hire ASP.NET Developers</Link>
                </h4>
                <p className="description">
                  ASP.net has become one of the top choices, especially after
                  all the latest MVC versions. AGM brings you expert and
                  experienced ASP.Net developers for hire so that you can have
                  the best options to realize your idea into reality.
                </p>
              </div>
            </div>
            <div className="col-md-6 col-lg-6 d-flex align-items-stretch mb-5 mb-lg-0">
              <div className="icon-box" data-aos="fade-up" data-aos-delay="400">
                <div className="icon">
                  <i className="bx bx-world"></i>
                </div>
                <h4 className="title">
                  <Link to="">Hire Ecommarce Developers</Link>
                </h4>
                <p className="description">
                  Developing your e-commerce portal? AGM has a talented pool of
                  e-commerce developers for hire. Today, an e-commerce website
                  is highly influenced by many factors, and our experts can take
                  care of that for you.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default HireDevelopers;
